import React, { useState } from "react";
import "styles/global.scss";
import DashboardLayout from '@components/layouts/DashboardLayout';
import { ProvideAuth } from "util/auth.js";
import '../helpers/initFA';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'rsuite/lib/Slider/styles/common.less';
import 'tippy.js/dist/tippy.css';
import 'styles/inspo.css';
import AppContext from '../context/Context';

function MyApp({ Component, pageProps }) {
  const [isFluid, setIsFluid] = useState(false);
  const [isRTL, setIsRTL] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [isTopNav, setIsTopNav] = useState(false);
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false);
  const [currency, setCurrency] = useState('$');
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpenSidePanel, setIsOpenSidePanel] = useState(false);
  const [navbarStyle, setNavbarStyle] = useState('card');

  const toggleModal = () => setIsOpenSidePanel(prevIsOpenSidePanel => !prevIsOpenSidePanel);
  const value = {
    isRTL,
    isDark,
    isFluid,
    setIsRTL,
    isTopNav,
    currency,
    setIsDark,
    setIsFluid,
    toggleModal,
    setIsTopNav,
    navbarStyle,
    setCurrency,
    showBurgerMenu,
    setNavbarStyle,
    isOpenSidePanel,
    setShowBurgerMenu,
    setIsOpenSidePanel,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed
  };

  const Layout = Component.layout || DashboardLayout;
  const showNav = Component.showNav === null || typeof Component.showNav === 'undefined' ? true : Component.showNav;
  return (
    <AppContext.Provider value={value}>
      <ProvideAuth>
        <Layout showNav={showNav}>
          <Component {...pageProps} />
          <ToastContainer pauseOnFocusLoss={false} newestOnTop={true} />
        </Layout>
      </ProvideAuth>
    </AppContext.Provider>
  );
}

export default MyApp;
