import React, { useState } from 'react';
import Link from 'next/link';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/avatar.png';
import Avatar from '../common/Avatar';

const ProfileDropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => false); //!prevState);
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        //let windowWidth = window.innerWidth;
        //windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        //let windowWidth = window.innerWidth;
        //windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={team3} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        {props.user && (<div className="bg-white rounded-soft py-2" style={{ cursor: 'pointer' }}>
          <DropdownItem>
            Logged in as {props.user ? props.user.email : ''}
          </DropdownItem>
          <DropdownItem>
            <Link href="/auth/signout">
              <a>
                Sign Out
            </a>
            </Link>
          </DropdownItem>
        </div>)}
        {!props.user && (<div className="bg-white rounded-soft py-2" style={{ cursor: 'pointer' }}>
          <DropdownItem>
            <Link href="/auth/signin">
              <a>
                Sign In
            </a>
            </Link>
          </DropdownItem>
        </div>)}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
